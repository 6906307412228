@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Source Serif Pro", serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in {
  animation: slideIn 0.5s ease-out forwards;
}
